<template>
  <div :class="[$attrs.class, 'scroll-custom flex min-h-full w-full flex-col']">
    <Header />

    <div
      class="content mt-[46px] flex flex-col bg-grey-50 md:mt-[82px] lg:mt-0"
    >
      <slot />
    </div>

    <Footer />

    <ContactModal />

    <CommonToast
      :show="toastStore.show"
      :position="toastStore.position"
      :type="toastStore.type"
      :message="toastStore.message"
      :duration="toastStore.duration"
      :icon="toastStore.icon"
      @close="closeToast"
    />
  </div>
</template>

<script>
import Header from '~/components/TheHeader.vue'
import Footer from '~/components/TheFooter.vue'
import ContactModal from '~/components/contact/Modal.vue'
import CommonToast from '~/components/common/Toast.vue'
import { mapStores } from 'pinia'
import { useToastStore } from '~/stores/toast'

export default {
  components: {
    CommonToast,
    ContactModal,
    Header,
    Footer,
  },
  computed: {
    ...mapStores(useToastStore),
  },
  methods: {
    closeToast() {
      this.$toast.clear()
    },
  },
}
</script>

<style scoped>
.content {
  min-height: calc(100vh - 48px);
}

@media (width >= 768px) {
  .content {
    min-height: calc(100vh - 84px);
  }
}

@media (width >= 1280px) {
  .content {
    min-height: calc(100vh - 153px);
  }
}
</style>
